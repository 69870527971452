import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { isPointInPolygon } from 'geolib';
import swal from 'bootstrap-sweetalert';
import { Modal, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import actions from 'store/actions/ManageCustomers';

import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import targetValue, { targetValueNumber } from 'utils/targetValue';

import { useAddLocation, useUpdateLocation } from 'api/v2/locations';

import MapAddWellsite from '../MapAddWellsite';

// TODO: Combine with other wellsite modals
const WellSiteModal = ({
  wellSite,
  setValue,
  setValueNumber,
  modalTitle,
  customers,
  showModal,
  closeModal,
  action,
  clearModalWellSite,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [saveClicked, setSaveClicked] = useState(false);

  const {
    name,
    contactPhone,
    address,
    city,
    state,
    latitude,
    longitude,
    specialInstructions,
    customerId,
    geofenceRange,
    polygonGeofence,
    polygonGeofenceCoords,
    email,
  } = wellSite;

  const { mutate: addLocation } = useAddLocation();
  const { mutate: updateLocation } = useUpdateLocation(wellSite.id);
  const [polyFenceEnabled, setPolyFenceEnabled] = useState(polygonGeofence ?? false);
  const [polyFenceCoords, setPolyFenceCoords] = useState(polygonGeofenceCoords ?? []);
  const submitActions = { add: addLocation, edit: updateLocation };

  const locationInPolyFence = useMemo(() => {
    if (!polyFenceEnabled || !polyFenceCoords.length) {
      return true;
    }

    return isPointInPolygon(
      { lat: Number(latitude), lng: Number(longitude) },
      polyFenceCoords
    );
  }, [polyFenceEnabled, polyFenceCoords, latitude, longitude]);

  const submitFunction = (e) => {
    e.preventDefault();
    setSaveClicked(true);
    const data = {
      ...wellSite,
      locationType: 1,
      polygonGeofence: Boolean(polyFenceEnabled),
      polygonGeofenceCoords: polyFenceEnabled ? polyFenceCoords : null,
      contactNo: contactPhone
    };

    if (data.polygonGeofence) {
      delete data.geofenceRange;
    }

    submitActions[action](
      data,
      {
        onSuccess: () => {
          if (action === 'add') {
            swal('Successfully created new location.', '', 'success');
          } else {
            swal('Successfully updated location.', '', 'success');
          }

          closeModal();
        },
        onSettled: () => setSaveClicked(false),
      }
    );
  }

  useEffect(() => () => clearModalWellSite(), [clearModalWellSite]);

  const customerList = customers.map(item => ({
    value: item.id,
    label: `${item.id} | ${item.name}`,
  }));

  useEffect(() => {
    const disabled = (
      !name ||
      !customerId ||
      !state ||
      !Number(latitude) ||
      latitude > 90 ||
      latitude < -90 ||
      !Number(longitude) ||
      longitude > 180 ||
      longitude < -180 ||
      !specialInstructions ||
      (!polyFenceEnabled && !Number(geofenceRange)) ||
      !locationInPolyFence

    )
    setSaveDisabled(disabled);

  }, [name, customerId, state, latitude, longitude, specialInstructions, geofenceRange, locationInPolyFence, polyFenceEnabled]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModal} onHide={closeModal}>
        <form
          onSubmit={submitFunction}
          className="m-t"
          role="form">
          <Modal.Header>
            <Modal.Title as="h4">{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="admin-customers_wellsite-modal_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  required
                  options={customerList}
                  onChange={item => setValue(item.value, 'customerId')}
                  value={customerList.find(item => item.value === customerId)}
                  label="Choose Customer"
                  isDisabled={action === 'edit'}
                  testSelector="admin-customers_wellsite_customer_select"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <InputMask
                  mask="999999999999"
                  maskChar={null}
                  value={contactPhone}
                  onChange={e => setValue(e, 'contactPhone')}
                  label="Contact number">
                  {inputProps => (
                    <Input
                      {...inputProps}
                      type="text"
                      testSelector="admin-customers_wellsite-modal_phone_input"
                    />
                  )}
                </InputMask>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'address')}
                  value={address}
                  label="Address"
                  id="address"
                  testSelector="admin-customers_wellsite-modal_address_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'city')}
                  value={city}
                  label="City"
                  testSelector="admin-customers_wellsite-modal_city_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'state')}
                  value={state}
                  label="State"
                  required
                  testSelector="admin-customers_wellsite-modal_state_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValueNumber(e, 'latitude')}
                  value={latitude}
                  label="Latitude"
                  min={-90}
                  max={90}
                  type="number"
                  required
                  testSelector="admin-customers_wellsite-modal_lat_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValueNumber(e, 'longitude')}
                  value={longitude}
                  label="Longitude"
                  min={-180}
                  max={180}
                  type="number"
                  required
                  testSelector="admin-customers_wellsite-modal_lng_input"
                />
              </div>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  noMargin
                  className="float-top"
                  onChange={e => setValue(e, 'email')}
                  value={email || ''}
                  label="Email"
                  testSelector="admin-customers_wellsite-modal_email_input"
                />
              </Col>
              <Col md={6}>
                <Textarea
                  onChange={e => setValue(e, 'specialInstructions')}
                  value={specialInstructions}
                  label="Location Special Instructions"
                  required
                  rows="3"
                  testSelector="admin-customers_wellsite-modal_instructions_input"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Button
                onClick={() => setPolyFenceEnabled(!polyFenceEnabled)}
                colour={polyFenceEnabled ? 'orange' : 'blue'}
              >
                <Icon icon="polygon" />
              </Button>
              <Button
                onClick={() => setPolyFenceEnabled(!polyFenceEnabled)}
                colour={polyFenceEnabled ? 'blue' : 'orange'}
              >
                <Icon icon="location_pin" />
              </Button>
              <Col md={4}>
                {!polyFenceEnabled && (
                  <Input
                    type="number"
                    onChange={e => setValueNumber(e, 'geofenceRange')}
                    value={geofenceRange}
                    label="Geofence range in Miles"
                    required
                    min={0.1}
                    max={30}
                    step={0.1}
                    testSelector="locations_input_geofence_input"
                  />
                )}
              </Col>
            </Row>
            {!locationInPolyFence && (
              <div
                className="nested-no-outer-gutter"
                align="center"
                style={{ color: 'red' }}>
                <p>
                  Location is outside the geofence
                </p>
              </div>
            )}
            <Row>
              <div className="col-md-12">
                <MapAddWellsite
                  action={action}
                  selectPolyFenceCoords={setPolyFenceCoords}
                  polyFenceEnabled={polyFenceEnabled}
                  polyFenceCoords={polyFenceCoords}
                />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={closeModal}
              colour="white"
              children="Close"
              testSelector="admin-customers_wellsite-modal_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              disabled={saveDisabled || saveClicked}
              testSelector="admin-customers_wellsite-modal_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  wellSite: state.manageCustomers.modalWellSite,
});

const mapDispatchToProps = dispatch => ({
  setValueNumber: (value, name) =>
    dispatch(actions.setValue(targetValueNumber(value), name)),
  setValue: (value, name) =>
    dispatch(actions.setValue(targetValue(value), name)),
  setCustomer: name => dispatch(actions.setCustomer(name)),
  geocodeLocation: () => dispatch(actions.geocodeLocation()),
  clearModalWellSite: () => dispatch(actions.clearModalWellSite()),
});

export const WellSiteModalTest = WellSiteModal; // for Tests

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellSiteModal);
